import { Home } from "../../pages/Home/Home"
import Club from "../../pages/Club"
import Education from "../../components/Education"

export const routesProvider = {
  home: {
    path: "/",
    element: <Home />
  },

  club: {
    path: "/club",
    element: <Club />
  },

  education: {
    path: "/education",
    element: <Education />
  }

}