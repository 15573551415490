import './Club.scss'
import ClubHero from '../../components/ClubHero';
import ClubAbout from '../../components/ClubAbout';
import Footer from '../../components/Footer';
import ClubPrice from '../../components/ClubPrice';


export const Club = () => {
    return (
        <div className='club'>
            <ClubHero />
            <ClubAbout />
            <ClubPrice />
            <Footer />
        </div>
    )
}