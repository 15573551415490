import './Home.scss'
import Hero from '../../components/Hero';
import Consultation from '../../components/Consultation';
import Questions from '../../components/Questions';
import Earnp2p from '../../components/Earnp2p';
import Education from '../../components/Education';
import ClosedClub from '../../components/ClosedClub';
import Footer from '../../components/Footer';

export const Home = () => {
 
  return (
    <div className='home'>
      <Hero />
      <Consultation />
      <Questions />
      <Earnp2p />
      <Education />
      <ClosedClub />
      <Footer />
    </div>
  )
}